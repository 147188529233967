// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./GillSans-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./GillSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./GillSans-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "gillSans";
    src:  url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
    font-weight: 400;
}

@font-face {
    font-family: "gillSans";
    src:  url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: "gillSans";
    src:  url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 800;
}`, "",{"version":3,"sources":["webpack://./src/style/fonts/gillSans/gillSans.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,4DAAmD;IACnD,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,gEAAmD;IACnD,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,gEAAwD;IACxD,gBAAgB;AACpB","sourcesContent":["@font-face {\n    font-family: \"gillSans\";\n    src:  url('./GillSans-Regular.woff') format('woff');\n    font-weight: 400;\n}\n\n@font-face {\n    font-family: \"gillSans\";\n    src:  url('./GillSans-Bold.ttf') format('truetype');\n    font-weight: 600;\n}\n\n@font-face {\n    font-family: \"gillSans\";\n    src:  url('./GillSans-ExtraBold.ttf') format('truetype');\n    font-weight: 800;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
