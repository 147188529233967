import { NavbarConfig } from "@energylab/shared-components-mono/build/constants/config/navbarConfig";

export const navbarConfig: NavbarConfig = {
    fullWidth: false,
    navbarItems: [
        {
            id: "challenges",
            translationKey: "menuItem.challenges",
            redirectTo: { path: "challenges" }
        },
        {
            id: "events",
            translationKey: "menuItem.events",
            redirectTo: { path: "events" }
        },
        {
            id: "news",
            translationKey: "menuItem.news",
            redirectTo: { path: "news" }
        },
        {
            id: "gallery",
            translationKey: "menuItem.gallery",
            redirectTo: { path: "gallery" }
        },
        {
            id: "webshop",
            translationKey: "menuItem.webshop",
            redirectTo: {
                path: "webshop"
            }
        },
        {
            id: "benefits",
            translationKey: "menuItem.benefits",
            redirectTo: {
                path: "benefits"
            }
        },
        {
            id: "static",
            translationKey: "menuItem.static",
            redirectTo: { path: "static" }
        },
        {
            id: "contact",
            translationKey: "menuItem.contact",
            redirectTo: { path: "contact" }
        }
    ]
};
