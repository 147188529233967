import { ConfigReducerState } from "@energylab/shared-components-mono/build/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components-mono/build/utils/browserLocale";
import * as environment from "environment";
import { cloudinaryImages } from "./cloudinaryImages";
import { articleTagsConfig } from "./constants/config/articleTagsConfig";
import { auth } from "./constants/config/authConfig";
import { challengeConfig } from "./constants/config/challengeConfig";
import { coinsConfig } from "./constants/config/coinsConfig";
import { commentsConfig } from "./constants/config/commentsConfig";
import { contactConfig } from "./constants/config/contactConfig";
import { contentConfig } from "./constants/config/contentConfig";
import { eventsConfig } from "./constants/config/eventsConfig";
import { footerConfig } from "./constants/config/footerConfig";
import { galleryConfig } from "./constants/config/galleryConfig";
import { leaderboardConfig } from "./constants/config/leaderboardConfig";
import { likesConfig } from "./constants/config/likesConfig";
import { logoutConfig } from "./constants/config/logoutConfig";
import { nationalities } from "./constants/config/nationalitiesConfig";
import { newUsersConfig } from "./constants/config/newUsersConfig";
import { nicknameConfig } from "./constants/config/nicknameConfig";
import { notificationsConfig } from "./constants/config/notificationsConfig";
import { providers } from "./constants/config/providersConfig";
import { resetPasswordConfig } from "./constants/config/resetPasswordConfig";
import { routeConfig } from "./constants/config/routeConfig";
import { seo } from "./constants/config/seoConfig";
import { socialConfig } from "./constants/config/socialConfig";
import { socialShareConfig } from "./constants/config/socialShareConfig";
import { webshopConfig } from "./constants/config/webshopConfig";
import { whiteListSignUpConfig } from "./constants/config/whiteListSignUpConfig";
import { defaultDashboardPlacesConfig } from "./constants/config/dashboardPlacesConfig";
import { profileConfig } from "./constants/config/profileConfig";
import { registerConfig } from "./constants/config/registerConfig";
import { locales } from "./constants/config/locales";
import { navbarConfig } from "./constants/config/navbarConfig";

export const defaultLocale = "en";

export const config: ConfigReducerState = {
    whiteListSignUpConfig,
    leaderboardConfig,
    enableCountry: true,
    dashboardPlacesConfig: defaultDashboardPlacesConfig,
    registerConfig,
    profileConfig,
    socialShareConfig,
    hideSocialLogin: true,
    hideSocialLinks: true,
    dashboardTopPadding: "30px",
    socialConfig,
    ambassadorImage: "",
    auth,
    navbarConfig,
    commentsConfig,
    eventsConfig,
    contentConfig,
    contactConfig,
    companyLogo: cloudinaryImages.logo,
    companyName: "Energy Lab",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig,
    socialTags: ["energylab"],
    likesConfig,
    webshopConfig,
    notificationsConfig,
    providers,
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig,
    nationalities,
    selectedLanguage: getBrowserLocale(defaultLocale, locales),
    seo,
    uploadPreset: "demov2",
    newUsersConfig,
    articleTagsConfig,
    nicknameConfig,
    footerConfig,
    challengeConfig,
    galleryConfig,
    logoutConfig,
    resetPasswordConfig,
    password: environment.password,
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex"
    ],
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"]
};
