import { profileStructure } from "../profile/profileStructure";

export const profileConfig = {
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex",
        "role"
    ],
    structure: profileStructure,
    valuesToForm: (values) => {
        if (!values) {
            return {};
        }

        const profileJSON = values.profile || {};
        const profilePrivacy = values.privacy || {};

        return {
            givenName: values.givenName,
            familyName: values.familyName,
            useNickname: Boolean(profilePrivacy.nickname),
            nickname: profilePrivacy.nickname,
            birthDate: profileJSON.birthDate,
            country: profileJSON.country,
            nationality: profileJSON.nationality,
            department: profileJSON.department,
            locale: values.locale,
            company: profileJSON.company,
            sex: profileJSON.sex,
            role: profileJSON.role
        };
    }
};
