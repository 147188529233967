import { NicknameWrapper } from "@energylab/shared-components-mono/build/components/profile/profileForm/profileFormStyle";
import { FormStructureScheme } from "@energylab/shared-components-mono/build/components/UI/form/formBuilderManager/types";
import { SEX } from "@energylab/shared-components-mono/build/utils/sex";
import * as React from "react";
import { FormatMessage } from "@energylab/shared-components-mono/build/components/formatMessage/formatMessage";
import { locales } from "../config/locales";
import { customNationalities } from "../nationalities";
import { departments } from "../departments";

export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                }
            ]
        },
        {
            title: "auth.fields.useNickname",
            type: "checkbox",
            property: "useNickname",
            styledComponent: NicknameWrapper
        },
        {
            placeholder: "auth.fields.nickname",
            type: "text",
            property: "nickname",
            disableWhen: {
                property: "useNickname",
                valueIs: false
            },
            requireWhen: {
                property: "useNickname",
                valueIs: true
            }
        },
        {
            title: "auth.fields.birthDate",
            type: "date",
            property: "birthDate",
            required: true,
            disabled: false
        },
        {
            title: "auth.fields.nationality",
            type: "select",
            options: customNationalities,
            hide: false,
            property: "nationality",
            required: true
        },
        {
            title: "auth.fields.department",
            type: "cascader",
            property: "department"
        },
        {
            title: "auth.fields.selectedLanguage",
            type: "select",
            options: locales.map(language => (
                {
                    title: <FormatMessage id={`locale.${language}`} />,
                    value: language
                }
            )),
            property: "locale",
            required: true
        },
        {
            title: "auth.fields.sex",
            type: "radio",
            options: [
                { title: <FormatMessage id="male" />, value: SEX.male },
                { title: <FormatMessage id="female" />, value: SEX.female }
            ],
            property: "sex",
            required: true
        },
        {
            title: "form.entity",
            type: "select",
            options: [
                {
                    title: "Demo Prospect",
                    value: "Demo Prospect"
                },
                {
                    title: "Energy Lab",
                    value: "Energy Lab"
                },
                {
                    title: "Peak4",
                    value: "Peak4"
                },
                {
                    title: "Better Minds at Work",
                    value: "Better Minds at Work"
                }
            ],
            property: "company"
        },
        {
            title: "form.role",
            type: "select",
            options: [
                {
                    title: "Employee",
                    value: "employee"
                },
                {
                    title: "Prospect",
                    value: "prospect"
                },
                {
                    title: "Tester",
                    value: "tester"
                }
            ],
            property: "role",
            required: true
        }
    ]
};
