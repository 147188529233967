import * as React from "react";
import { FormatMessage } from "@energylab/shared-components-mono/build/components/formatMessage/formatMessage";
import { FormStructureScheme } from "@energylab/shared-components-mono/build/components/UI/form/formBuilderManager/types";
import { LocaleLink } from "@energylab/shared-components-mono/build/components/UI/localeLink/localeLink";
import { emailWhitelistedCreator } from "@energylab/shared-components-mono/build/utils/emailWhitelistedCreator";
import { compareToFirstPasswordCreator } from "@energylab/shared-components-mono/build/utils/compareToFirstPassword";
import { SEX } from "@energylab/shared-components-mono/build/utils/sex";
import { getPasswordRules } from "@energylab/shared-components-mono/build/utils/passwordRule";
import { password } from "environment";
import { customNationalities } from "../nationalities";
import { locales } from "../config/locales";

export const registerStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        {
            title: "register.profilePoliciesStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "register.acceptPrivacy",
                    titleValues: {
                        link: (
                            <LocaleLink target="_blank" route="privacy">
                                <FormatMessage id="register.privacy" />
                            </LocaleLink>
                        )
                    },
                    type: "checkbox",
                    property: "privacy",
                    required: true
                },
                {
                    title: "register.acceptDisclaimer",
                    titleValues: {
                        link: (
                            <LocaleLink target="_blank" route="disclaimer">
                                <FormatMessage id="register.disclaimer" />
                            </LocaleLink>
                        )
                    },
                    type: "checkbox",
                    property: "disclaimer",
                    required: true
                }
            ]
        },
        {
            title: "register.basicStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.email",
                    type: "text",
                    property: "email",
                    validationRules: [
                        { type: "email", message: "auth.error.invalidEmail" },
                        {
                            validatorBuilder: emailWhitelistedCreator,
                            message: "error.emailNotWhitelisted"
                        }
                    ],
                    required: true
                },
                {
                    title: "form.password",
                    type: "password",
                    property: "password",
                    validationRules: getPasswordRules(password),
                    required: true
                },
                {
                    title: "register.passwordInfo",
                    type: "p",
                    property: "passwordInfo",
                    hide: true
                },
                {
                    title: "form.confirmPassword",
                    type: "password",
                    property: "passwordConfirm",
                    validationRules: [
                        {
                            validatorBuilder: compareToFirstPasswordCreator,
                            message: "auth.error.notEqualPassword"
                        }
                    ],
                    required: true
                },
                {
                    title: "form.companyCodeIntro",
                    type: "p",
                    property: "companyCodeIntro",
                    hide: true
                },
                {
                    title: "form.companyCode",
                    type: "text",
                    property: "companyCode",
                    hide: true
                },
                {
                    title: "form.supportMail",
                    type: "p",
                    property: "supportMail",
                    hide: true
                }
            ]
        },
        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "form.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                },
                {
                    title: "form.sex",
                    type: "radio",
                    options: [
                        { title: <FormatMessage id="male" />, value: SEX.male },
                        { title: <FormatMessage id="female" />, value: SEX.female },
                        { title: <FormatMessage id="intersexual" />, value: SEX.other }
                    ],
                    property: "sex",
                    required: true
                },
                {
                    title: "form.birthDate",
                    type: "date",
                    property: "birthDate",
                    required: true
                },
                {
                    title: "form.nationality",
                    type: "select",
                    options: customNationalities,
                    property: "nationality"
                },
                {
                    title: "form.language",
                    type: "select",
                    options: locales.map(locale => (
                        {
                            title: <FormatMessage id={`locale.${locale}`} />,
                            value: locale
                        }
                    )),
                    property: "locale"
                },
                {
                    title: "form.entity",
                    type: "select",
                    options: [
                        {
                            title: "Energy Lab",
                            value: "Energy Lab"
                        },
                        {
                            title: "Peak4",
                            value: "Peak4"
                        },
                        {
                            title: "Better Minds at Work",
                            value: "Better Minds at Work"
                        },
                        {
                            title: "Other",
                            value: "Other"
                        }
                    ],
                    property: "company"
                },
                {
                    title: "form.role",
                    type: "select",
                    options: [
                        {
                            title: "Employee",
                            value: "employee"
                        },
                        {
                            title: "Prospect",
                            value: "prospect"
                        },
                        {
                            title: "Tester",
                            value: "tester"
                        }
                    ],
                    property: "role",
                    required: true
                }
            ]
        },
        {
            title: "register.profileStepExtraTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.avatar",
                    type: "uploadAvatar",
                    property: "avatar"
                },
                {
                    title: "form.useNickname",
                    type: "checkbox",
                    property: "publicDisplayNameType"
                },
                {
                    title: "form.useNickname",
                    type: "text",
                    property: "nickname",
                    showWhen: {
                        property: "publicDisplayNameType",
                        valueIs: true
                    },
                    required: true
                },
                {
                    title: "form.showActivities",
                    type: "checkbox",
                    property: "showActivities"
                }
            ]
        }
    ]
};
