import { lazy } from "react";

const ContentContainer = lazy(() => import("@energylab/shared-components-mono/build/modules/pages/content/contentContainer"));
const ContentCardOverviewContainer = lazy(() => import("@energylab/shared-components-mono/build/modules/benefits/contentCardOverview/contentCardOverviewContainer"));
const BenefitDetailContainer = lazy(() => import("@energylab/shared-components-mono/build/modules/benefits/benefits/benefitDetail/benefitDetailContainer"));

export const ROUTES = [
    {
        path: "static",
        element: <ContentContainer type="dummyStaticPage" />
    },
    {
        path: "benefits",
        children: [
            {
                path: ":benefitId",
                element: <BenefitDetailContainer />
            },
            {
                index: true,
                element: (
                    <ContentCardOverviewContainer
                        contentType="benefit"
                        introKey="benefits"
                        metaDataKey="benefits"
                    />
                )
            }
        ]
    },

];

export const REDIRECTS = [];
