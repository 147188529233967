import { RouteConfigType } from "@energylab/shared-components-mono/build/routes/routes";
import { defaultLocale } from "../../config";
import { locales } from "./locales";
import { ROUTES } from "../../routes";
import { getRoutes } from "@energylab/shared-components-mono/build/routes";

export const routeConfig: RouteConfigType = {
    locales,
    defaultLocale,
    routes: {
        private: getRoutes(ROUTES)
    },
    redirects: [],
    hideNavigationWhenLoggedOut: true
};
