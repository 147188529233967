import { SeoConfig } from "@energylab/shared-components-mono/build/constants/config/seoConfig";

export const seo: SeoConfig = {
    pageTitle: "Energy Lab Demo",
    title: {
        nl: "Energy Lab Demo",
        fr: "Energy Lab Demo",
        en: "Energy Lab Demo"
    },
    description: {
        nl: "",
        fr: "",
        en: ""
    },
    image: "",
    site: ""
};
