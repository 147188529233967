import * as React from "react";
import { FormatMessage } from "@energylab/shared-components-mono/build/components/formatMessage/formatMessage";
import { nationalities } from "./config/nationalitiesConfig";

export const customNationalities = [
    ...nationalities.map(nat => ({
        title: <FormatMessage id={`nationality.${nat.code}`} />,
        value: nat.code
    })),
    {
        title: <FormatMessage id="nationality.OTHER" />,
        value: "OTHER"
    }
];
